import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
//export default () => (
class AboutPage extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title={this.props.data.site.siteMetadata.title}
      >
        <div>
          <h1>I'd love to talk! Email me at the address below</h1>
          <p>
            <a href="mailto:0@arturas.me">0@arturas.me</a>
          </p>
        </div>
        <Helmet
          script={[
            {
              src: '//platform.linkedin.com/in.js',
              type: 'text/javascript',
            },
          ]}
        />
        <Helmet
          script={[
            {
              type: 'IN/MemberProfile',
              'data-id': 'http://www.linkedin.com/in/arturassotnicenko',
              'data-format': 'inline',
              'data-related': 'false',
            },
          ]}
        />
      </Layout>
    )
  }
}
export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
